<template>
  <div class="rechargeListView">
    <van-sticky>
      <van-nav-bar title="充值记录" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" loading-text="..." @load="onLoad">
      <div v-for="(item, key) in list" :key="key" class="xiax_item">
        <van-panel :title="'充值金额:' + item.money" :status="item.status">
          <div class="replay">
            <div class="replayTitle">充值备注 :</div>
            <div class="replayContent" v-if="!item.remark">无备注信息</div>
            <div class="replayContent" v-if="item.remark">{{ item.remark }}</div>
          </div>
          <div class="replay" v-if="item.uptime">
            <div class="replayTitle">审核时间 :</div>
            <div class="replayContent">{{ item.uptime }}</div>
          </div>
        </van-panel>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getRechargeLists } from '@/api/users';
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //获取充值记录
    getLists() {
      getRechargeLists({ page: this.page, pageSize: this.pageSize })
        .then((response) => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch((error) => {});
    },
    onLoad() {
      this.getLists();
    },
  },
};
</script>
<style lang="stylus">
.rechargeListView
  .van-list
    margin-top: 0.3rem
    .xiax_item
      padding: 0.15rem 0.4rem
      .van-panel__header
        margin-bottom: 0.26667rem
      .replay:nth-child(1)
        padding: 0 0.42667rem 0.26667rem 0.42667rem
      .replay
        padding: 0.26667rem 0.42667rem
        .replayTitle
          display: inline-block
          font-weight: bold
        .replayContent
          display: inline-block
</style>
